var render = function render(){
  var _vm$$refs$setting;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "main-row"
  }, [_c('div', {
    staticClass: "view-card"
  }, [_c('img', {
    attrs: {
      "src": _vm.$platformInfo.logo
    }
  }), !_vm.formData.cover ? _c('div', {
    staticClass: "message-box"
  }, [_vm._v(" 请添加图片 "), _c('span', {
    staticClass: "t-right-n"
  })]) : _c('el-image', {
    staticClass: "upload-image",
    attrs: {
      "fit": "cover",
      "src": _vm.formData.cover,
      "preview-src-list": [_vm.formData.cover]
    }
  }, [_c('i', {
    staticClass: "el-icon-picture",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  })])], 1), _c('div', {
    staticClass: "editor-container"
  }, [_c('div', {
    staticClass: "edit-main"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.formData.cover,
      expression: "!formData.cover"
    }],
    staticClass: "upload-box"
  }, [_c('el-upload', {
    ref: "upload",
    staticClass: "picture-uploader",
    attrs: {
      "action": "",
      "accept": "image/gif, image/jpeg, image/jpg, image/png, image/webp",
      "list-type": "picture-card",
      "auto-upload": false,
      "show-file-list": false,
      "on-change": _vm.uploaderChange
    }
  }, [_c('div', {
    staticClass: "default-upload-btn",
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('i', {
    staticClass: "el-icon-plus"
  }), _c('div', [_vm._v("添加图片")])])]), _c('div', {
    staticClass: "color9 mt20 center"
  }, [_vm._v(" 支持JPG、GIF、PNG等格式，单张图片不超过5M ")])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formData.cover,
      expression: "formData.cover"
    }],
    staticClass: "show-img"
  }, [_c('el-image', {
    attrs: {
      "fit": "cover",
      "src": _vm.formData.cover
    }
  }), _c('div', {
    staticClass: "edit-img",
    on: {
      "click": _vm.handleUpload
    }
  }, [_c('i', {
    staticClass: "el-icon-edit-outline fs18 color6"
  })])], 1), _c('public-setting', {
    ref: "setting",
    staticClass: "edit-set",
    attrs: {
      "detailForm": _vm.detailForm
    }
  }), _c('c-foot-bar', {
    attrs: {
      "form-data": Object.assign({}, _vm.formData, (_vm$$refs$setting = _vm.$refs.setting) === null || _vm$$refs$setting === void 0 ? void 0 : _vm$$refs$setting.formData),
      "before-submit": _vm.validateForm
    }
  })], 1)])]), _c('f-upload', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "uploader"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }