<template>
  <div class="container">
    <div class="main-row">
      <div class="view-card">
        <img :src="$platformInfo.logo" />
        <div class="message-box" v-if="!formData.cover">
          请添加图片
          <span class="t-right-n"></span>
        </div>
        <el-image
          v-else
          fit="cover"
          :src="formData.cover"
          :preview-src-list="[formData.cover]"
          class="upload-image"
        >
          <i slot="error" class="el-icon-picture"></i>
        </el-image>
      </div>
      <div class="editor-container">
        <div class="edit-main">
          <div class="upload-box" v-show="!formData.cover">
            <el-upload
              action=""
              ref="upload"
              accept="image/gif, image/jpeg, image/jpg, image/png, image/webp"
              class="picture-uploader"
              list-type="picture-card"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="uploaderChange"
            >
              <div slot="default" class="default-upload-btn">
                <i class="el-icon-plus"></i>
                <div>添加图片</div>
              </div>
            </el-upload>
            <div class="color9 mt20 center">
              支持JPG、GIF、PNG等格式，单张图片不超过5M
            </div>
          </div>
          <div class="show-img" v-show="formData.cover">
            <el-image fit="cover" :src="formData.cover"></el-image>
            <div class="edit-img" @click="handleUpload">
              <i class="el-icon-edit-outline fs18 color6"></i>
            </div>
          </div>
          <!--发表设置-->
          <public-setting
            :detailForm="detailForm"
            ref="setting"
            class="edit-set"
          ></public-setting>
          <c-foot-bar
            :form-data="{ ...formData, ...$refs.setting?.formData }"
            :before-submit="validateForm"
          ></c-foot-bar>
        </div>
      </div>
    </div>
    <f-upload v-show="false" ref="uploader"></f-upload>
  </div>
</template>

<script>
import FUpload from "@/components/upload";
import publicSetting from "./_components/publicSetting";
import CFootBar from "./_components/footBar";
import { getMessageDetail } from "@/api/message";
export default {
  components: { FUpload, publicSetting, CFootBar },
  data() {
    return {
      formData: {
        cover: "",
        publishType: 4 //图片消息
      },
      detailForm: {}
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    //获取详情
    async getDetail() {
      const res = await getMessageDetail(this.$route.query.id);
      if (res) {
        this.formData.cover = res.cover;
        this.formData.id = res.id;
        this.detailForm = res;
      }
    },
    //触发上传图片
    handleUpload() {
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    //上传图片
    async uploaderChange(file) {
      let limitSize = 1024 * 1024 * 5;
      if (file.size > limitSize) {
        this.$showError("图片过大");
        return;
      }
      this.formData.cover = await this.$refs.uploader.upload(file.raw, true);
    },
    // 验证表单是否填写完整
    validateForm() {
      const { cover } = this.formData;
      const { formData, regularMass, dayError } = this.$refs.setting;
      let msg;
      if (!cover) {
        msg = "请添加图片";
      } else if (formData.dataType == 1 && regularMass && dayError) {
        msg = "无剩余次数";
      }
      if (msg) {
        this.$showError(msg);
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  margin: -20px;
  height: calc(100% + 40px);
  background: #f8f8f8;
  overflow: hidden;
  .main-row {
    display: flex;
    align-items: flex-start;
    position: relative;
    .view-card {
      display: flex;
      padding: 30px 0 30px 40px;
      img {
        width: 45px;
        height: 45px;
        border-radius: 5px;
        margin-right: 20px;
      }
      .message-box {
        width: 266px;
        min-height: 45px;
        border-radius: 5px;
        background: #fff;
        position: relative;
        padding: 13px;
        font-size: 15px;
      }
      .upload-image {
        width: 183px;
        min-width: 50px;
        max-height: 258px;
        background: #eee;
        color: #999;
        font-size: 30px;
        border-radius: 5px;
        @include flex-xyc;
      }
    }
    .editor-container {
      flex: 1;
      display: flex;
      justify-content: center;
      overflow-y: auto;
      padding: 30px 40px 30px 0;
      height: calc(100vh - 60px);
      .edit-main {
        width: 800px;
        height: fit-content;
        background: #fff;
        border-radius: 8px;
        padding-top: 30px;
        .upload-box {
          padding: 100px 0;
          ::v-deep .picture-uploader {
            @include flex-xc;
            .el-upload--picture-card {
              width: 100px;
              height: 100px;
              color: #aaa;
              line-height: inherit;
              @include flex-xyc;
            }
            .el-icon-plus {
              font-size: 45px;
            }
          }
        }
        .show-img {
          width: 234px;
          height: 330px;
          margin: 0 auto;
          position: relative;
          ::v-deep .el-image {
            width: 100%;
            height: 100%;
            background: #eee;
            border-radius: 5px;
          }
          .edit-img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            background: #fff;
            cursor: pointer;
            @include flex-xyc;
          }
        }
        .edit-set {
          margin: 50px 80px 0;
        }
      }
    }
  }
}
</style>
